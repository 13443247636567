const { MALAYSIA, SINGAPORE } = require('./countries-map')
const { PRODUCT_TYRE, PRODUCT_LUBRICANT, PRODUCT_BATTERY } = require('./product-types')

// To be used in sorting
const priorityBrands = {
  [PRODUCT_TYRE]: {
    [MALAYSIA]: [
      // Pirelli
      'PRL',
      'PI',

      // Nexen
      'NEX',
      'NX',

      // Michelin
      'MCL',
      'MI',
      'MIC',

      // Goodyear
      'GDY',
      'GY',

      // Hankook
      'HKK',
      'HK',

      // Bridgestone
      'BGT',
      'BR',
    ],
    [SINGAPORE]: [
      // Nexen
      'NEX',
      'NX',

      // Michelin
      'MCL',
      'MI',
      'MIC',

      // Goodyear
      'GDY',
      'GY',

      // Pirelli
      'PRL',
      'PI',

      // Hankook
      'HKK',
      'HK',

      // Bridgestone
      'BGT',
      'BR',
    ],
  },
  [PRODUCT_LUBRICANT]: {
    [MALAYSIA]: [],
    [SINGAPORE]: [],
  },
  [PRODUCT_BATTERY]: {
    [MALAYSIA]: ['VTA', 'ENG'],
    [SINGAPORE]: ['VAR', 'ENZ'],
  },
}

module.exports = { priorityBrands }
