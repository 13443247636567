import { useQuery } from 'react-query'
import API from '.'
import { serializeQuery } from './utils'
import { SALES_ROLE } from '../../../server/constants/roles'
import { useAuth } from '../hooks/context/AuthContext'

const ENDPOINT = '/api/batteries'
const QUERY_KEY = 'batteries'

export const useGetBatteries = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'list', query],
    async () => (await API.get(serializeQuery(ENDPOINT, query))).data,
    config
  )

export const useGetBatteryBrands = (query = {}, config = {}) =>
  useQuery(
    ['battery_brands', 'brand-list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/brands`, query))).data,
    config
  )

export const useGetBatteriesFrontEnd = (
  page = 1,
  limit = 20,
  sort = 'brandAsc',
  hasSalesDealer = false,
  filters = {},
  customer = '',
  config = {}
) => {
  const { user } = useAuth()

  const batteries = useGetBatteries(
    {
      ...filters,
      customer,
      inStockOnly: Number(
        filters.inStockOnly?.[0] || (!hasSalesDealer && user?.role === SALES_ROLE ? 0 : 1)
      ),
      page,
      limit,
      sort,
    },
    config
  )

  return batteries
}

export const useGetBatteryQty = (battery, config = {}) => {
  if (!battery) return

  const { itemRef = '', site, subSites } = battery

  const params = {
    itemRef,
    site,
    site: subSites?.join(',') || site,
  }

  const query = new URLSearchParams(params).toString()

  const path = `${ENDPOINT}/qty?${query}`

  return useQuery([QUERY_KEY, 'qty', query], async () => (await API.get(path)).data, config)
}

export const useGetBatteryModels = (query = {}, config = {}) =>
  useQuery(
    ['battery_models', 'list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/models`, query))).data,
    config
  )

export const useGetBatteryNames = (query = {}, config = {}) =>
  useQuery(
    ['battery_names', 'list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/names`, query))).data,
    config
  )
