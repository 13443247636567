const PRODUCT_TYRE = 'tyre'
const PRODUCT_LUBRICANT = 'lubricant'
const PRODUCT_BATTERY = 'battery'
const PRODUCT_BRAKE = 'brake'

const productDict = {
  [PRODUCT_TYRE]: {
    name: PRODUCT_TYRE,
    label: 'Tyre',
    namePlural: 'tyres',
    labelPlural: 'Tyres',
    nameAX: 'TYRE',
    orderQtyCap: 25,
    salesQtyCap: 100,
  },
  [PRODUCT_LUBRICANT]: {
    name: PRODUCT_LUBRICANT,
    label: 'Lubricant',
    namePlural: 'lubricants',
    labelPlural: 'Lubricants',
    nameAX: 'LUBE',
    orderQtyCap: 25,
    salesQtyCap: 100,
  },
  [PRODUCT_BATTERY]: {
    name: PRODUCT_BATTERY,
    label: 'Battery',
    namePlural: 'batteries',
    labelPlural: 'Batteries',
    nameAX: 'BATTERY',
    orderQtyCap: 25,
    salesQtyCap: 100,
  },
  [PRODUCT_BRAKE]: {
    name: PRODUCT_BRAKE,
    label: 'Brake',
    namePlural: 'brakes',
    labelPlural: 'Brakes',
    nameAX: 'BRAKE',
    orderQtyCap: 25,
    salesQtyCap: 100,
  },
}

/**
 * Store all end-user available product types
 * For use in autocomplete or product type selection pages
 */
const PRODUCT_TYPES = [
  { val: PRODUCT_TYRE, label: 'Tyre', icon: '' },
  { val: PRODUCT_LUBRICANT, label: 'Lubricant', icon: '' },
  { val: PRODUCT_BATTERY, label: 'Battery', icon: '' },
  { val: PRODUCT_BRAKE, label: 'Brake', icon: '' },
]

const prioritySequence = [PRODUCT_TYRE, PRODUCT_BATTERY, PRODUCT_LUBRICANT, PRODUCT_BRAKE]

module.exports = {
  PRODUCT_TYRE,
  PRODUCT_LUBRICANT,
  PRODUCT_BATTERY,
  PRODUCT_BRAKE,
  PRODUCT_TYPES,
  productDict,
  prioritySequence,
}
