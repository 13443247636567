import React, { useEffect } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { DEFAULT_COUNTRY } from 'shared/constants/countries-map'
import { HeaderWrapper } from './ui/Header'
import HeaderLogo from './ui/HeaderLogo'
import IconButton from '@material-ui/core/IconButton'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { useSnackbar } from 'notistack'
import { usePostTrack } from '../api/analytics'

const ErrorBoundary = ({ resetError = () => {}, eventId = '' }) => {
  const { enqueueSnackbar } = useSnackbar()

  const { mutateAsync } = usePostTrack()

  useEffect(() => {
    mutateAsync({ event: 'Unhandled Error' }).catch(() => {})
  }, [])

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      textAlign='center'
      px={2}
    >
      <HeaderWrapper
        upperHeader={
          <IconButton size='small' onClick={() => (window.location.href = '/')}>
            <HeaderLogo country={DEFAULT_COUNTRY} />
          </IconButton>
        }
        lowerHeader={<></>}
      />
      <img src={'/images/red-alert-icon.png'} />
      <Typography variant='h4' color='error' gutterBottom>
        Oops! Something went wrong.
      </Typography>
      <Typography variant='body1' paragraph>
        We encountered an issue while loading the page. Please try refreshing or come back later.
      </Typography>
      <Typography variant='body1' paragraph>
        If the problem persists, please report this issue to us by providing the Event ID below.
      </Typography>
      <Typography variant='body2' color='textSecondary' paragraph>
        <strong>Event ID:</strong> <span>{eventId}</span>
        <IconButton
          onClick={() => {
            navigator.clipboard
              .writeText(eventId)
              .then(() => {
                enqueueSnackbar('Event ID has been copied to clipboard', {
                  variant: 'success',
                })
              })
              .catch((e) => console.error('Failed to copy to clipboard', e))
          }}
        >
          <FileCopyIcon size='small' />
        </IconButton>
      </Typography>
      <Button
        variant='contained'
        color='primary'
        size='large'
        onClick={() => resetError()}
        sx={{ mt: 2 }}
      >
        Refresh Page
      </Button>
    </Box>
  )
}

export default ErrorBoundary
