import React from 'react'

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SnackbarProvider } from 'notistack'
import moment from 'moment'

import { ThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import useGlobalTheme from './global.styles'

import * as Sentry from '@sentry/react'
import AuthProvider from './shared/hooks/context/providers/AuthProvider'
import KeepStockProvider from './shared/hooks/context/providers/KeepStockProvider'
import { DEFAULT_COUNTRY } from '../shared/constants/countries-map'
import { enableLandingPage } from '../shared/config/regional-configs'

const LandingApp = React.lazy(() => import('./landing/App'))
const AdminApp = React.lazy(() => import('./admin/App'))
const CsApp = React.lazy(() => import('./cs/App'))
const ClientApp = React.lazy(() => import('./client/App'))

import PrivacyPolicy from './landing/PrivacyPolicy'
import TermsAndConditions from './landing/TermsAndConditions'
import ErrorBoundary from './shared/components/ErrorBoundary'

const queryClient = new QueryClient()

const App = () => {
  const theme = useGlobalTheme()

  // Global moment locale
  moment.locale('en-sg')

  return (
    <SnackbarProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Sentry.ErrorBoundary
            fallback={({ resetError, eventId }) => (
              <ErrorBoundary resetError={resetError} eventId={eventId} />
            )}
          >
            <AuthProvider>
              <KeepStockProvider>
                <BrowserRouter>
                  <Switch>
                    <Route
                      path='/signup'
                      render={({ match }) =>
                        enableLandingPage[DEFAULT_COUNTRY] ? (
                          <React.Suspense fallback={<></>}>
                            <LandingApp match={match} />
                          </React.Suspense>
                        ) : (
                          <Redirect to='/' />
                        )
                      }
                    />
                    <Route path='/privacy-policy' component={PrivacyPolicy} />
                    <Route path='/terms-and-conditions' component={TermsAndConditions} />
                    <Route
                      path='/admin'
                      render={({ match }) => (
                        <React.Suspense fallback={<></>}>
                          <AdminApp match={match} />
                        </React.Suspense>
                      )}
                    />
                    <Route
                      path='/cs'
                      render={({ match }) => (
                        <React.Suspense fallback={<></>}>
                          <CsApp match={match} />
                        </React.Suspense>
                      )}
                    />
                    <Route
                      path='/'
                      render={({ match }) => (
                        <React.Suspense fallback={<></>}>
                          <ClientApp match={match} />
                        </React.Suspense>
                      )}
                    />
                  </Switch>
                </BrowserRouter>
              </KeepStockProvider>
            </AuthProvider>
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  )
}

export default App
